/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
// You can delete this file if you're not using it

exports.shouldUpdateScroll = () => {
  let loc = document.location.href

  //If the user is returning to a search in progress or closing a modal, do not move the scroll.
  if(!loc.includes('#')) {
    window.scrollTo(0,0)
  }
  
    return false
  }